@mixin flex($direction: row, $alignment: normal, $justification: normal, $wrap: wrap) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justification;
  align-items: $alignment;
  flex-wrap: $wrap;
}

@mixin b($point) {
  @if $point==desktop {
    @media (max-width: 1366px) and (min-width: 1024px) {
      @content;
    }
  } @else if $point==tablet {
    @media (max-width: 1000px) {
      @content;
    }
  } @else if $point==mobile {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $point==mobile3x {
    @media only screen and (-webkit-min-device-pixel-ratio: 3), only screen and (min-resolution: 3dppx), only screen and (min-resolution: 350dpi) {
      @content;
    }
  } @else {
    @media (max-width: $point + 'px') {
      @content;
    }
  }
}
