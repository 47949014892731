@import "./scss/theme.scss";
@import "./scss/mixins.scss";

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  background-color: $scrollbar-background;
}

*::-webkit-scrollbar-thumb {
  background-color: $scrollbar;
}

body {
  margin: 0px;
  font-family: $font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  background-color: $bg;
}

h1 {
  font-weight: bold;
  line-height: 55px;
  margin: 0;
}

input {
  font-family: $font;
}

input::placeholder {
  font-family: $font;
  color: $grey;
  opacity: 0.6;
}

.App {
  width: 100%;
  height: 100%;
}

.App-container {
  position: relative;
  margin: auto;
  max-width: 1400px;
  background-position: top, center;
  background-repeat: repeat;
}

.body {
  @include flex(row,normal,space-between);
  padding: 50px + 96px 30px 260px 30px;
  user-select: none;
  @include b('mobile') {
    padding: 90px + 33px 10px 260px 10px;
  }
  @include b('tablet') {
    justify-content: center;
  }
}

.info {
  margin: 49px 30px 30px 30px;
  max-width: 500px;
  font-size: 18px;
}

.xinfin-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  min-height: 86px;
  max-width: 518px;
  margin: 28px 0;
  padding: 26px;
  border: 6px solid #FFFFFF;
  border-radius: 20px;
  @media screen and (max-width: 580px) {
    justify-content: center;
  }
}

.xinfin-info-second {
  display: flex;
  flex-direction: column;
  padding: 21px 26px;
  background-color: white;
  border: 6px solid #FFFFFF;
  border-radius: 20px;
  max-width: 518px;
  font-size: 16px;
  line-height: 18px;
  &--element {
    margin-bottom: 20px;
    opacity: .5;
  }
  &--element:first-child {
    opacity: 1;
  }
  &--element:nth-child(2) {
    text-transform: uppercase;
  }
  &--element:nth-child(4) {
    opacity: 1;
  }
  &--element:nth-child(5) {
    opacity: 1;
  }

  .box {
    background-color: #F4F4F4;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    padding: 6px 12px;
    //border: 1px solid black;
  }
  .box-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F4F4F4;
    //padding: 6px;
    height: 35px;
    width: 35px;
    border-radius: 6px;
    text-align: center;
    //border: 1px solid black;
    margin-right: 4px;
  }
  .text {
    //border: 1px solid black;
    width: 160px;
    height: 51px;
    font-size: 15px;
  }
  .box-container {
    //border: 1px solid black;
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
  }
  .box-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include b('mobile') {
      flex-direction: column;
    }
  }
}


.xinfin-live {
  background-color: #24BE58;
  padding: 6px 12px;
  margin-left: 6px;
  border-radius: 6px;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
}

.xinfin-upper-text {
  @media screen and (max-width: 580px) {
    justify-content: center;
  }
}

.circle-white {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #FFFFFF;
  margin: 6px 0 0 4px;
}

.description {
  margin-top: 30px;
  line-height: 26px;
}

.link {
  text-decoration: none;
  color: $link;
  opacity: 1;
}

.link-underlined {
  color: $link;
  opacity: 1;
  word-wrap: normal;
  white-space: nowrap;
}

.links {
  line-height: 24px;
  &-item {
    @include flex(row,center);
    margin-top: 40px;
    text-decoration: none;
    font-weight: bold;
    line-height: 32px;
  }
  a {
    text-decoration: none;
    color: $link;
  }
  &-arrow {
    &-down {
      margin: 10px;
      pointer-events: none;
      width: 15px;
      -webkit-transition: transform 0.2s ease-in;
      transition: transform 0.2s ease-in;
    }
    &-up {
      margin: 10px;
      pointer-events: none;
      width: 15px;
      transform: rotate(180deg);
      -webkit-transition: transform 0.2s ease-in;
      transition: transform 0.2s ease-in;
    }
  }
}

.button {
  @include flex(row,center,space-between);
  cursor: pointer;
  background: $gradient-button;
  padding: 0px 28px 0px 20px;
  line-height: 42px;
  font-size: 14px;
  color: $white;
  font-weight: 700;
  white-space: nowrap;
  &-big {
    @include flex(row,center,space-between);
    cursor: pointer;
    background: $gradient-button;
    padding: 0px 28px 0px 20px;
    line-height: 62px;
    font-size: 14px;
    color: $white;
    font-weight: 700;
  }
  &-arrow {
    margin-right: 20px;
  }
  &-disabled {
    background: $gradient-button-disabled !important;
    pointer-events: none;
    cursor: default;
  }
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.m10 {
  margin: 10px;
}

.m20 {
  margin: 20px;
}

.mh10 {
  margin-left: 10px;
  margin-right: 10px;
}

.ml10 {
  margin-left: 10px;
}

.mr10 {
  margin-right: 10px;
}

.pointer {
  cursor: pointer;
}

.color-red {
  color: $red;
}

