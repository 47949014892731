@import "../../scss/mixins.scss";

.modal {
  &-open {
    @include flex(column,center,center);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100000000;
  }

  &-closed {
    visibility: hidden;
    display: none;
  }

  &-container {
    @include flex(column,center,center);
    position: relative;
    min-width: 300px;
    max-width: 600px;
    min-height: 150px;
    background-color: white;
    border-radius: 0px;
    color: black;
    text-align: center;
    font-size: 14px;
    line-height: 26px;
    padding: 20px;
    margin: 10px;
  }

  &-image {
    width: 40px;
    height: 40px;
  }

  &-header {
    @include flex(row,center,space-between);
    font-weight: 700;
    font-size: 30px;
  }

  &-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 12px;
    height: 12px;
    padding: 10px;
    cursor: pointer;
  }
}
