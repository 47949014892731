$bg: #F4F4F4;
$white: #fff;
$blue: #2873FB;
$blue2: #a8bfe9;
$blue3: #84a4de;
$orange: #d5aa6c;
$red: red;
$lightorange: #FFEAC0;
$white-opacity: rgba(255, 255, 255, 0.67);
$grey: #242424;
$grey2: #777;
$lightgrey: #F4F4F4;
$lightgrey2: #dedede;
$link: #2873FB;
$scrollbar: rgba(133, 133, 133, 0.3);
$scrollbar-background: rgba(208, 208, 208, 0.2);
$messageColor: #7994AE;

$gradient-button: linear-gradient(90deg, $blue2 0px, $blue2 7px, $blue 7px, $blue 100%);
$gradient-button-disabled: linear-gradient(90deg, $blue2 0px, $blue2 7px, $blue3 7px, $blue3 100%);

$shadow-container: 0px 4px 60px rgba(0, 0, 0, 0.05);
$shadow-dropdown: 0px 8px 15px rgba(0, 0, 0, 0.15);
$shadow-menu: 0 4px 40px rgba(0,0,0,.14);
$shadow-border: 0 0 0 1px lightgrey inset;

$font: 'Ubuntu', normal;
$font2: 'Comfortaa', normal;
