@import "../../scss/theme.scss";
@import "../../scss/mixins.scss";

$fs: 16px;
$height: 60px;
$heightMessage: 42px;

.header {
  @include flex(row,center,center,nowrap);
  font-family: $font2;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  width: 100%;
  height: $height;
  background-color: $white;
  z-index: 999;
  user-select: none;
  box-shadow: $shadow-menu;
  &-container {
    @include flex(row,center,space-between,nowrap);
    height: $height;
    width: 100%;
    max-width: 1400px;
  }
  &-left-group {
    @include flex(row,center,center,nowrap);
    height: $height;
    padding: 28px 28px 28px 60px;
    @include b('mobile') {
      padding: 28px 28px 28px 28px;
    }
  }
  &-right-group {
    @include flex(row,center,center,nowrap);
    height: $height;
    padding: 0px 60px 0px 0px;
    @include b('mobile') {
      display:none;
    }
  }
  &-item {
    @include flex(row,center,center);
    cursor: pointer;
    padding: 20px 15px;
    font-size: 16px;
    border-radius: 5px;
    a {
      text-decoration: none;
      color: black;
    }
  }
  &-item:hover {
    @include flex(row,center,center);
    cursor: pointer;
    padding: 16px 15px;
    font-size: 16px;
    border-radius: 5px;
    background-color: $lightgrey;
  }
  &-item-arrow {
    margin-left: 6px;
    opacity: 0.4;
  }
  &-logo {
    width: 157px;
    height: 34px;
  }
  &-menu-mobile-closed {
    display:none;
  }
  &-menu-mobile-open {
    @include flex(column,center,flex-start);
    box-sizing: border-box;
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 60px;
    padding: 0px 30px 120px 30px;
    flex-grow: 0;
    background-color: $white;
    z-index: 999;
  }
}

.header-menu-btn {
  cursor: pointer;
  z-index: 1000;
  &-wrapper {
    @include flex(column,center,center);
    padding: 0px 28px;
    height: $height;
    cursor: pointer;
    z-index: 996;
    user-select: none;
    font-size: $fs;
  }
  &-open {
    background-color: transparent;
  }
}

.header-menu {
  &-footer {
    @include flex(column,center,space-around);
    box-sizing: border-box;
    position: absolute;
    left: 0;
    bottom: 60px;
    padding: 20px 10px;
    margin-top: 30px;
    width: 100%;
    height: 160px;
    z-index: 997;
    font-size: 15px;
    user-select: none;
    &-logo-group {
      @include flex(row,center,center);
    }
    &-logo {
      cursor: pointer;
      margin: 0px 10px;
    }
  }
}

.header-message {
  @include flex(row, center, center, nowrap);
  font-family: $font;
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
  top: $height;
  left: 0;
  width: 100%;
  height: $heightMessage;
  background-color: $messageColor;
  z-index: 998;
  user-select: none;
  a {
    @include flex(row, center, center, nowrap);
    height: 100%;
    width: 100%;
    color: $white;
    text-decoration: none;
    text-align: center;
  }
  &:hover {
    text-decoration: underline;
  }
}

