@import "../../scss/theme.scss";
@import "../../scss/mixins.scss";

.input-container {
  @include flex(column);
  width: 100%;
  min-height: 62px;
  box-sizing: border-box;
  position: relative;
  background-color: $lightgrey;
  margin: 5px 0 0 0;

  &-inner {
    @include flex(row,center);
    width: 100%;
    height: 100%;
  }

  &-input {
    width: 100%;
  }
}

.input {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0px;
  outline: none;
  border: none;
  background-color: $lightgrey;
  padding: 20px;
  font-size: 14px;
  line-height: 34px;
}

.input-big {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0px;
  outline: none;
  border: none;
  background-color: $lightgrey;
  padding: 3px 20px 20px 20px;
  font-size: 30px;
  line-height: 34px;
  font-weight: 600;
}

.input-big::placeholder {
  font-weight: 600;
}

.input-medium {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0px;
  outline: none;
  border: none;
  background-color: $lightgrey;
  padding: 0px 5px 0px 20px;
  font-size: 30px;
  line-height: 62px;
  font-weight: 600;
}

.input-medium::placeholder {
  font-weight: 600;
}

.input-label {
  @include flex(row,center,space-between);
  position: absolute;
  padding: 4px 6px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0px;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
  color: $grey;
  opacity: 0.6;

  &-inner {
    margin: 0px 10px 0px 5px;
    border: 2px solid $blue;
    border-radius: 3px;
    color: $blue;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &-big {
    height: 30px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 0px;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    color: $grey;
    opacity: 0.6;
    padding: 20px 20px 0px 20px;
  }
}

.error {
  background-color: $lightorange;
}

