@import "../../scss/theme.scss";
@import "../../scss/mixins.scss";

.footer {
  @include flex(column,center,space-around);
  box-sizing: border-box;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 20px 10px;
  margin-top: 30px;
  width: 100%;
  min-height: 160px;
  z-index: 997;
  font-size: 15px;
  background: linear-gradient(0deg, $bg 0%, $bg 50%, transparent 100%);
  user-select: none;

  &-logo-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  &-logo {
    cursor: pointer;
    margin: 0px 10px;
  }
}



