@import "../../scss/theme.scss";
@import "../../scss/mixins.scss";

$fs: 16px;
$heightDropdown: 60px;

.header-dropdown {
  @include flex(row,center,center,nowrap);
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  user-select: none;
  font-size: $fs;
  border-radius: 5px;
  height: $heightDropdown;
  padding: 0 5px 0 5px;

  &-open {
    @include flex(row,center,center);
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    user-select: none;
    font-size: $fs;
    border-radius: 5px;
    height: $heightDropdown;
    padding: 0 5px 0 5px;
    box-shadow: $shadow-border;
  }

  &-round {
    @include flex(row,center,center,nowrap);
    margin: 0 5px 0 5px;
    height: 40px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    user-select: none;
    font-size: $fs;
    border-radius: 20px;
    border: 1px solid lightgrey;
  }

  &-round:hover {
    @include flex(row,center,center,nowrap);
    margin: 0 5px 0 5px;
    height: 40px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    user-select: none;
    font-size: $fs;
    border-radius: 20px;
    border: 1px solid lightgrey;
    background-color: $lightgrey;
  }

  &-arrow {
    pointer-events: none;
    margin: 0px 0px 5px 10px;
    width: 6px;
    height: 6px;
    border-width: 2px 2px 0 0;
    border-style: solid;
    border-color: #999;
    transform: rotate(135deg);
    transition: transform 0.2s linear, margin 0.2s linear;
  }

  &-arrow-up {
    pointer-events: none;
    margin: 0px 0px 0px 10px;
    width: 6px;
    height: 6px;
    border-width: 2px 2px 0 0;
    border-style: solid;
    border-color: #999;
    transform: rotate(-45deg);
    transition: transform 0.2s linear, margin 0.2s linear;
  }

  &-list {
    @include flex(column);
    visibility: hidden;
    position: absolute;
    top: 60px;
    left: 0px;
    box-sizing: border-box;
    z-index: 2;
    opacity: 0;
    transition: visibility 0.1s, opacity 0.1s ease-in;
    font-weight: 500;
  }

  &-list-open {
    @include flex(column);
    border-radius: 5px;
    position: absolute;
    top: 60px;
    left: 0px;
    min-width: 100%;
    max-height: 50vh;
    box-sizing: border-box;
    background-color: $white;
    border: 1px solid lightgrey;
    z-index: 1000;
    opacity: 1;
    transition: visibility 0.1s, opacity 0.1s ease-in;
    overflow: auto;
    font-weight: 500;
    a:not(:last-child) {
      border-bottom: 1px solid lightgrey;
    }
  }

  &-item {
    @include flex(row,center);
    text-decoration: none;
    word-wrap: normal;
    white-space: nowrap;
    color: black;
    padding: 0px 15px;
    line-height: 50px;
    cursor: pointer;
    background-color: $white;
    &-image {
      width: 26px;
      height: 26px;
    }
  }

  &-item:hover {
    @include flex(row,center);
    padding: 0px 15px;
    line-height: 50px;
    cursor: pointer;
    background-color: $lightgrey;
  }

  &-label {
    @include flex(row,center,space-between);
    height: $heightDropdown;
    padding: 0px 15px;
    z-index: 999;
    &-left {
      @include flex(row,center,space-between);
      pointer-events: none;
    }
  }

  &-label-round {
    @include flex(row,center,space-between);
    padding: 8px 8px;
  }

  &-arrow-down {
    opacity: 0.4;
    pointer-events: none;
    margin-left: 5px;
    width: 15px;
    height: 10px;
    -webkit-transition: transform 0.2s ease-in;
    transition: transform 0.2s ease-in;
  }

  &-arrow-right {
    opacity: 0.4;
    pointer-events: none;
    margin-left: 5px;
    width: 15px;
    height: 10px;
    transform: rotate(180deg);
    -webkit-transition: transform 0.2s ease-in;
    transition: transform 0.2s ease-in;
  }
}

.header-dropdown:hover {
  @include flex(row,center,center,nowrap);
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  user-select: none;
  font-size: $fs;
  border-radius: 5px;
  background-color: $lightgrey;
  height: $heightDropdown;
}
