@import "../../scss/theme.scss";
@import "../../scss/mixins.scss";

.modal-manual-swap {
  width: 100%;
  &-header {
    @include flex(row,center,space-between);
    font-weight: 700;
    font-size: 30px;
  }
  &-close {
    width: 12px;
    height: 12px;
    padding: 15px;
    cursor: pointer;
  }
  &-comment {
    margin-top: 20px;
    text-align: left;
    font-size: 16px;
    line-height: 27px;
  }
  &-receiver {
    color: $orange;
    font-weight: 700;
  }
  &-button-container {
    width: 120px;
  }
  &-label {
    text-align: left;
    width: 100%;
    margin: 15px 0 0 0;
    font-size: 13px;
    font-weight: 400;
    color: $grey;
    text-transform: uppercase;
    opacity: 0.6;
  }
  &-field {
    @include flex(row,center,space-between);
    box-sizing: border-box;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 15px 15px;
    font-size: 16px;
    font-weight: 500;
    color: $blue;
    background-color: $lightgrey;
    &-content {
      overflow: hidden;
      flex-shrink: 1;
      text-overflow: ellipsis;
    }
  }
  &-button-copy {
    width: 14px;
    height: 14px;
    &-container {
      @include flex(row,center,center);
      width: 29px;
      height: 29px;
      background-color: $white;
      border-radius: 50%;
      cursor: pointer;
    }
  }
}
