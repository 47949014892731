@import "../../scss/theme.scss";
@import "../../scss/mixins.scss";

.dropdown {
  @include flex(column);
  width: 100%;
  box-sizing: border-box;
  position: relative;
  background-color: $lightgrey;
  padding: 0px 0px;
  margin: 5px 0 0 0;
  line-height: 62px;
  cursor: pointer;
  font-weight: 500;
  user-select: none;

  &-list {
    @include flex(column);
    visibility: hidden;
    position: absolute;
    top: 62px;
    left: 0px;
    width: 100%;
    box-sizing: border-box;
    background-color: $lightgrey;
    box-shadow: $shadow-dropdown;
    z-index: 2;
    opacity: 0;
    transition: visibility 0.1s, opacity 0.1s ease-in;
    font-weight: 500;
  }

  &-list-open {
    @include flex(column);
    position: absolute;
    top: 62px;
    left: 0px;
    width: 100%;
    max-height: 50vh;
    box-sizing: border-box;
    background-color: $lightgrey;
    box-shadow: $shadow-dropdown;
    z-index: 2;
    opacity: 1;
    transition: visibility 0.1s, opacity 0.1s ease-in;
    overflow: auto;
    font-weight: 500;
  }

  &-item {
    @include flex(row,center);
    padding: 0px 15px;
    line-height: 62px;
    cursor: pointer;
    -webkit-transition: background-color 0.25s ease-out;
    transition: background-color 0.25s ease-out;
    &-image {
      width: 32px;
      height: 32px;
      margin-right: 15px
    }
  }

  &-item:hover {
    @include flex(row,center);
    padding: 0px 15px;
    line-height: 62px;
    cursor: pointer;
    background-color: $lightgrey2;
    -webkit-transition: background-color 0.01s ease-in;
    transition: background-color 0.01s ease-in;
  }

  &-label {
    @include flex(row,center,space-between);
    padding: 0px 15px;
    &-left {
      pointer-events: none;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &-arrow-down {
    pointer-events: none;
    width: 15px;
    height: 10px;
    -webkit-transition: transform 0.2s ease-in;
    transition: transform 0.2s ease-in;
  }

  &-arrow-right {
    pointer-events: none;
    width: 15px;
    height: 10px;
    transform: rotate(180deg);
    -webkit-transition: transform 0.2s ease-in;
    transition: transform 0.2s ease-in;
  }

  &-disabled {
    @include flex(column);
    width: 100%;
    box-sizing: border-box;
    position: relative;
    //background-color: $lightgrey;
    //background: linear-gradient(to right, $lightgrey 8%, #FFFFFF 18%, $lightgrey 33%);
    padding: 0px 0px;
    margin: 5px 0 0 0;
    line-height: 62px;
    cursor: pointer;
    font-weight: 500;
    user-select: none;
    pointer-events: none;
    opacity: .5;

    @extend .animated-background-input;
  }
}

.animated-background-input {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #F6F6F6;
  background: linear-gradient(to right, $lightgrey 8%, #FFFFFF 18%, $lightgrey 33%);
  background-size: 800px 104px;
  position: relative;
}

@keyframes placeHolderShimmer{
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
}


